<template>
  <v-dialog
      max-width="70%"
      v-model="dialog"
  >
    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}" :loading="loading">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">{{ tooltip_text }}</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="non_field" name="None Field"
                                    rules=""
                                    v-slot="{ errors }">
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="subject_code" name="Subject Code"
                                    rules="required"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Subject Code*"
                      hint="CIB Subject Code"
                      required
                      autocomplete="nope"
                      v-model="subject_code"
                      maxlength="32"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              <v-icon>
                {{ activator_icon }}
              </v-icon>
              Find
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"

export default {
  name: "findCIB",
  props: ['passed_item', 'icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn', 'snackbar_text', 'tooltip_text', 'loading'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    subject_code: null
  }),
  methods: {
    handle_submit() {
      this.submit_loading = true
      this.$emit("find", this.subject_code)
      this.submit_loading = false
      this.dialog = false
    }
  },
  watch: {},
  mounted() {
  }
}
</script>

<style scoped>
</style>