import axios from "axios";
import store from "../store";

const createAxiosClient = () => {
    return axios.create({
        baseURL: store.state.baseURL,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `mamla ${store.state.accessToken}`,
            "multi": "true",
        },
    });
};

export default createAxiosClient;