<template>
  <v-container>
    <subject-list/>
  </v-container>
</template>

<script>
import SubjectList from "@/components/mamla/loan_mamla/subject_list"

export default {
  name: "AddLoanMamla",
  components: {SubjectList},
  methods: {},
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Add Loan Mamla'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>