const loan_mamla_urls = {
    subjects: {
        list: "mamla/borrower_brranch/",
    },
    file_conn: {
        single: "mamla/loanmamla/fileconnection/{fileid}",
    },
    // mamla_conn: {
    //   single: "mamla/loanmamla/mamlaconnection/{fileid}/",
    // },
    loan_mamla: {
        list: "mamla/loanmamla/mamlafile/",
        own_zone: "mamla/loanmamla/mamlafile/own_zone",
        own_circle: "mamla/loanmamla/mamlafile/own_circle",
        zone_wise: "mamla/loanmamla/mamlafile/zone_wise/{office_id}/",
        circle_wise: "mamla/loanmamla/mamlafile/circle_wise/{office_id}/",
        branch_wise: "mamla/loanmamla/mamlafile/branch_wise/{office_id}/",
        add: "mamla/loanmamla/mamlafile/",
        delete: "mamla/loanmamla/mamlafile/{fileid}/",
        select: "mamla/loanmamla/mamlafile/{fileid}/",
        involved_amount: "mamla/loanmamla/involved_amount/",
        dealing_officer: "mamla/loanmamla/dealing_officer/",
        dealing_officer_history: "mamla/loanmamla/dealing_officer/{fileid}",
        all_files: "mamla/loanmamla/mamlafile/all_files",
    },
    artho_rin: {
        list: "mamla/loanmamla/arthorin/",
        add: "mamla/loanmamla/arthorin/",
        blank: "mamla/loanmamla/arthorin_blank/",
        delete: "mamla/loanmamla/arthorin/{mamlaid}/",
        single: "mamla/loanmamla/arthorin/{fileid}/",
        lawyer: "mamla/loanmamla/arthorin_lawyer/", //patch method
        case_info: "mamla/loanmamla/arthorin_case_info/", //patch method
        court_info: "mamla/loanmamla/arthorin_court_info/", //patch method
        next_date: "mamla/loanmamla/arthorin_next_date/", //patch method
        dispose: "mamla/loanmamla/arthorin_disposal/",
    },
    history: {
        lawyer: {
            list: "mamla/lawyer_history/{mamla_id}/",
        },
        next_date: {
            list: "mamla/next_date/{mamla_id}/",
        },
    },
};

export default loan_mamla_urls;
