<template>
  <v-dialog
      max-width="70%"
      v-model="dialog"
      @input="get_list"
  >

    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}" :loading="loading">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">{{ tooltip_text }}</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="non_field" name="None Field"
                                    rules=""
                                    v-slot="{ errors }">
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="involved_amount" name="Involved Amount"
                                    rules="required|min_value:1"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Involved Amount*"
                      hint="Total Amount Stuck/Involved in this file"
                      required
                      autocomplete="nope"
                      v-model="item.involved_amount"
                      maxlength="32"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="dealing_officer" name="Dealing Officer"
                                    rules="objectNotEmpty:dealing_officer|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Dealing Officer*"
                      hint="Search by personnel no or name"
                      :items="dealing_officer"
                      :item-text="(item)=>item.personnel_no + ' | ' + item.name"
                      item-value="id"
                      v-model="item.dealing_officer"
                      required
                      autocomplete="nope"
                      :loading="dealing_officer_loading"
                  >
                  </v-autocomplete>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"
import personnel_urls from "@/constants/personnel_urls";

export default {
  name: "add_file_form",
  props: ['passed_item', 'icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn', 'url', 'snackbar_text', 'tooltip_text', 'loading'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    dealing_officer_loading: true,
    dealing_officer: [{personnel_no: "", name: ""}],
    item: {
      involved_amount: null,
      dealing_officer: {personnel_no: "", name: ""}
    },
    from_date_menu: false,
    to_date_menu: false,
    non_field_errors: [],
    dealing_officer_list_url: personnel_urls.personnel.list
  }),
  methods: {
    async add_new_item() {
      this.axios_conf
      // console.log(this.passed_item)
      let data = {
        borrower: this.passed_item.id,
        involved_amount: this.item.involved_amount,
        dealing_officer: this.item.dealing_officer,
      }
      console.log(data)
      let self = this
      await axios.post(this.url, data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$refs.vform.resetValidation()
            this.$refs.vform.reset()
            this.$emit('form_status', true, resp.data.obj)
            this.$store.commit("update_snackbar", {
              text: this.snackbar_text.replace('{value}', self.passed_item.subject_name),
              status: true
            })
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.non_field_errors = error.response.data.non_field_errors;
              this.$refs.observer.setErrors({
                involved_amount: error.response.data.errors.involved_amount,
                dealing_officer: error.response.data.errors.dealing_officer,
                non_field: error.response.data.errors.non_field_errors,
              });
            }
          })
      this.submit_loading = false
    },
    async edit_item() {
      this.axios_conf
      let data = this.item
      data.authorizer = {}
      data.closer = {}
      data.inputter = {}
      delete data.closing_timestamp
      delete data.authorize_timestamp
      await axios.patch(this.url.replace('{id}', data.id), data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.non_field_errors = []
            this.$emit('form_status', true)
            this.$store.commit("update_snackbar", {text: this.snackbar_text, status: true})
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.non_field_errors = error.response.data.non_field_errors;
              this.$refs.observer.setErrors({
                name: error.response.data.errors.name,
                from_date: error.response.data.errors.from_data,
                to_date: error.response.data.errors.to_date,
              });
            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      if (this.action === 'add') {
        this.add_new_item()
      } else if (this.action === 'edit') {
        this.edit_item()
      }
    },
    get_list() {
      this.axios_conf;
      this.submit_loading = true
      this.dealing_officer_loading = true
      axios.get(this.dealing_officer_list_url).then((resp) => {
        this.dealing_officer = resp.data.personnels
        this.$store.commit('setJWT', resp.data.key)
        this.submit_loading = false
        this.dealing_officer_loading = false
      }).catch((error) => {
        this.$refs.observer.setError({
          dealing_office: `Error occurred ${error.response.status}`
        })
      })
    }
  },
  watch: {
    // passed_item(val) {
    //   // console.log(val)
    //   this.item = JSON.parse(JSON.stringify(val))
    //   // console.log(this.item)
    // },
  },
  mounted() {
    // if (this.passed_item) {
    //   this.item = JSON.parse(JSON.stringify(this.passed_item))
    // }
  }
}
</script>

<style scoped>
</style>