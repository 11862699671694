<template>
  <v-container v-if="has_view_permission" class="px-0">
    <v-data-table
        :headers="column_headers"
        :items="table_items"
        sort-by="subject_code"
        class="elevation-5 yellow lighten-2"
        :loading="table_loading"
        loading-text="Loading... Please wait"
        item-key="id"
        name="subjects_table"
        :search="search"
    >
      <template v-slot:top>
        <v-card-title
            flat
        >
          CIB Subjects
          <v-spacer></v-spacer>
          <v-btn :loading="reload_btn" @click="get_lists" class="ma-1">
            <v-icon>
              mdi-reload
            </v-icon>
            Reload Data Table
          </v-btn>
          <findCIB
              activator_icon="mdi-magnify"
              tooltip_text="Find"
              big_btn=true
              form_title="Find CIB Subject from other branch"
              @find="handle_other_branch_cib"
              activator_name="Other branch CIB"
              :loading="reload_btn"
          />
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-3"
          ></v-text-field>
        </v-card-title>
      </template>
      <template v-slot:item.actions="{ item }">
        <add_file_form v-if="add_button" activator_name="Add New"
                       :form_title="`Add New File for \nSubject#${item.subject_name}`" :passed_item="item"
                       :url="add_url"
                       activator_icon="mdi-plus" action="add" @form_status="details_page"
                       tooltip_text="Add"
                       big_btn=true
                       :snackbar_text="`New File for Subject#${item.subject_name} has been created`">

        </add_file_form>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>


import axios from "axios";
import loan_mamla_urls from "@/constants/mamla/loan_mamla/loan_mamla";
import add_file_form from "@/components/mamla/loan_mamla/loan_mamla_sub_components/add_file_form"
import findCIB from "@/components/mamla/loan_mamla/loan_mamla_sub_components/findCIB.vue";
import cib_urls from "@/constants/cib_urls";
import createAxiosClient from "@/helpers/axios_helper";

export default {
  name: "SubjectList",
  components: {add_file_form, findCIB},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers('mamla_container.add_loanmamlafile')
    },
    add_button() {
      return this.$store.getters.permissionCheckers('mamla_container.add_loanmamlafile')
    },
  },
  data: () => ({
    table_items: [],
    skeleton_loader_attrs: {
      boilerplate: true,
    },
    show_snackbar: false,
    snackbar_text: '',
    list_url: loan_mamla_urls.subjects.list,
    add_url: loan_mamla_urls.loan_mamla.add,
    select_cib_url: cib_urls.subjects.select,
    table_loading: false,
    reload_btn: false,
    search: '',
    column_headers: [
      {
        text: 'Subject Code',
        align: 'center',
        filterable: true,
        value: 'subject_code',
      },
      {
        text: 'Subject Name',
        align: 'center',
        filterable: true,
        value: 'subject_name',
      },
      {
        text: 'Subject Type',
        align: 'center',
        filterable: true,
        value: 'subject_type',
      },
      {
        text: 'Business Type',
        align: 'center',
        filterable: true,
        value: 'business_type',
      },
      {
        text: 'Branch',
        align: 'center',
        filterable: true,
        value: 'br_code',
      },
      {
        text: 'Actions',
        align: 'center',
        filterable: false,
        value: 'actions',
      },
    ],
  }),
  methods: {
    get_lists() {
      this.axios_conf
      let self = this
      let config = {
        headers: {
          multi: 'true'
        }
      }
      this.reload_btn = true
      this.table_loading = true
      this.table_items = []
      axios.get(this.list_url, config).then((resp) => {
        this.table_items = resp.data.items
        this.$store.commit('setJWT', resp.data.key)
        self.table_loading = false
        this.reload_btn = false
      })
    },
    details_page(value, obj) {
      this.$router.push({name: 'LoanMamlaDetails', params: {fileid: obj}})
    },
    handle_other_branch_cib(value) {
      // console.log(value)
      let client = createAxiosClient()
      this.table_items = []
      this.table_loading = true
      this.reload_btn = true
      client.get(this.select_cib_url.replace("{subject_code}", value)).then((resp) => {
        this.table_items = resp.data.items
        this.$store.commit('setJWT', resp.data.key)
        this.table_loading = false
        this.reload_btn = false
      }).catch((err) => {
        this.table_loading = false
        this.reload_btn = false
        console.log("ERROR: ", err)
      })
    }
  },
  mounted() {
    this.get_lists()
  },
  watch: {}
}
</script>

<style>
div[name="subjects_table"] > div > table > tbody > tr:hover {
  background: #82B1FF !important;
}
</style>